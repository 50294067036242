import { addons } from '@storybook/addons';
import YounitedTheme from './YounitedTheme';

addons.setConfig({
  showPanel: true,
  panelPosition: 'bottom',
  theme: YounitedTheme,
  sidebar: {
    showRoots: true,
  }
});

